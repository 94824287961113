import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import type { IOrderData } from '@studiobuki/shared/dist/interfaces';
import { Logger } from '@studiobuki/shared/dist/logger';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { RoutingService } from 'src/app/services/routing.service';
import { OrderService } from '@studiobuki/web-core/lib/order';
import { FirebaseService } from '@studiobuki/web-core/lib/firebase';
import { ELoaderType, LoaderService } from '@studiobuki/web-core/lib/loader';
import { ROUTE_PATH_PARAM_ORDER_ID } from '@studiobuki/web-core/lib/routing';
import { getError } from '@studiobuki/shared/dist/utils';
import { scrollToSelector } from 'src/utils';
import type { IGiftOrderPageResolverData } from './gift-order-page.resolver';
import type { IFormData } from './interfaces';

const log = new Logger('GiftOrderPageComponent');

@Component({
  templateUrl: './gift-order-page.component.html',
  styleUrls: ['./gift-order-page.component.scss'],
})
export class GiftOrderPageComponent implements OnInit, OnDestroy {
  /** used to touch forms to make errors appear */
  public readonly touchEventEmitter = new EventEmitter<void>();

  public order!: IOrderData;

  private _form: IFormData = {};

  public step: 1 | 2 = 1;

  public steps = 1;

  public isNextDisabled = false;

  get isCompleted(): boolean {
    return this.step > this.steps;
  }

  private _sub = new Subscriber();

  constructor(
    private _route: ActivatedRoute,
    private _routing: RoutingService,
    private _orderService: OrderService,
    private _firebaseService: FirebaseService,
    private _loaderService: LoaderService,
    public media: MediaObserver,
  ) {
    const { data, paramMap } = this._route.snapshot;

    const { order } = data['resolved'] as IGiftOrderPageResolverData;

    if (!paramMap.get(ROUTE_PATH_PARAM_ORDER_ID)) {
      this._routing.goToGiftOrder(order.orderId);
    }

    this._handleOrder(order);
  }

  ngOnInit() {
    const { order } = this;

    this._sub.push(
      this._firebaseService.getUserOrder$(order.orderId).subscribe((_order) => {
        if (_order) {
          log.info('order updated', _order);
          this._handleOrder(_order);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public onEditCartClick() {
    this._goToBook();
  }

  public onBackClick() {
    this._goToBook();
  }

  public async onNextClick() {
    const isValid = this._validate();

    log.info('onNextClick', { isValid });

    if (!isValid) return;

    this._loaderService.show({ type: ELoaderType.payment, data: {} });
    await this._updateOrderData();
  }

  public onFormChange(data: IFormData) {
    this._form = data;
    this.isNextDisabled = false;
  }

  private _goToBook() {
    const book = this.order.books[0];

    if (!book) {
      alert(getError('book not found').message);
      return;
    }

    const giftId = this.order.gift;

    if (!giftId) {
      alert(getError('gift not found').message);
      return;
    }

    this._routing.goToBook(book.bookId, giftId);
  }

  private _validate() {
    const { formEmail, formShippingAddress } = this._form;

    const isFormEmailValid = !!formEmail;

    const isFormShippingAddressValid = !!formShippingAddress;

    if (!isFormEmailValid) {
      scrollToSelector('app-form-email');
    } else if (!isFormShippingAddressValid) {
      scrollToSelector('app-form-stripe-address');
    }

    const isValid = isFormEmailValid && isFormShippingAddressValid;

    this.isNextDisabled = !isValid;
    this.touchEventEmitter.next();

    return isValid;
  }

  private async _updateOrderData() {
    const { formEmail, formShippingAddress } = this._form;
    const { order } = this;

    if (!formEmail || !formShippingAddress)
      throw getError('missing required data');

    this.order.addressShippingData = {
      ...formShippingAddress,
      emailAddress: formEmail.email,
    };

    return this._orderService.updateOrder(order.orderId, order);
  }

  private _handleOrder(order: IOrderData) {
    this.order = order;

    if (order.error) {
      alert(order.error.message);
      this._routing.goToIndex();
      this._loaderService.hide();
      return;
    }

    if (order.barOrderId) {
      log.info('got barOrderId', new Date());
      this.step = 2;
      this._loaderService.hide();
    }
  }
}
