import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductionService } from '@studiobuki/web-core/lib/production';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { map } from 'rxjs/operators';
import { PRODUCTION_TIME_ZONE } from '@studiobuki/shared/dist/production/constants';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeComponent {
  public readonly arrivalDate$ =
    this._productionService.defaultExpectedArrivalDate$.pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  constructor(private _productionService: ProductionService) {}
}
