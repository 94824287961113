import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { BehaviorSubject } from 'rxjs';
// import { Logger } from '@shared/logger';

// const log = new Logger('LogoComponent');

@Component({
  selector: 'app-logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements AfterViewInit, OnDestroy {
  @Input() public inverted: boolean = false;

  @ViewChild('eyeLeft') private eyeLeft?: ElementRef<HTMLDivElement>;

  @ViewChild('eyeBallLeft') private eyeBallLeft?: ElementRef<HTMLDivElement>;

  @ViewChild('eyeRight') private eyeRight?: ElementRef<HTMLDivElement>;

  @ViewChild('eyeBallRight') private eyeBallRight?: ElementRef<HTMLDivElement>;

  private centerXGap = 0; // 37

  private centerX = 0;

  private centerYGap = 0; // 25

  private centerY = 0;

  private isEyeProcessed = false;

  public marginTop = new BehaviorSubject<CSSStyleDeclaration['marginTop']>('');

  public marginLeft = new BehaviorSubject<CSSStyleDeclaration['marginLeft']>(
    '',
  );

  private _sub = new Subscriber();

  // constructor() {}

  ngAfterViewInit() {
    // log.info('ngAfterViewInit')
    this._load();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  get eyeLeftElement() {
    return this.eyeLeft?.nativeElement;
  }

  get eyeBallLeftElement() {
    return this.eyeBallLeft?.nativeElement;
  }

  get eyeRightElement() {
    return this.eyeRight?.nativeElement;
  }

  get eyeBallRightElement() {
    return this.eyeBallRight?.nativeElement;
  }

  get eyeLeftElementWidth(): number {
    let value = 0;

    if (this.eyeLeftElement) {
      value = this.eyeLeftElement.getBoundingClientRect().width;
    }

    return value;
  }

  get eyeBallLeftElementWidth(): number {
    let value = 0;

    if (this.eyeBallLeftElement) {
      value = this.eyeBallLeftElement.getBoundingClientRect().width;
    }

    return value;
  }

  get eyeLeftElementTop(): number {
    let value = 0;

    if (this.eyeLeftElement) {
      value = this.eyeLeftElement.getBoundingClientRect().top;
    }

    return value;
  }

  get eyeLeftElementLeft(): number {
    let value = 0;

    if (this.eyeLeftElement) {
      value = this.eyeLeftElement.getBoundingClientRect().left;
    }

    return value;
  }

  get eyeRightElementTop(): number {
    let value = 0;

    if (this.eyeRightElement) {
      value = this.eyeRightElement.getBoundingClientRect().top;
    }

    return value;
  }

  get eyeRightElementLeft(): number {
    let value = 0;

    if (this.eyeRightElement) {
      value = this.eyeRightElement.getBoundingClientRect().left;
    }

    return value;
  }

  private updateCenter(
    xLeft: number = this.eyeLeftElementLeft,
    yLeft: number = this.eyeLeftElementTop,
    size: number = this.eyeLeftElementWidth,
  ) {
    this.centerX = xLeft + size / 2 + this.centerXGap;
    this.centerY = yLeft + size / 2 + this.centerYGap;
  }

  private _load() {
    if (!this.eyeLeftElement) {
      return;
    }

    this.updateCenter();

    const r = (this.eyeBallLeftElementWidth / 3) * 2; // 3
    let x: number;
    let y: number;
    let x1: number;
    let y1: number;
    let x2: number;
    let y2: number;

    const mousemove = (e: MouseEvent) => {
      if (!this.isEyeProcessed) {
        this.isEyeProcessed = true;

        this.updateCenter();

        x1 = this.centerX + window.scrollX;
        y1 = this.centerY + window.scrollY;
        x2 = e.pageX;
        y2 = e.pageY;
        y =
          (r * (y2 - y1)) /
            Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1)) +
          y1;
        x = ((y - y1) * (x2 - x1)) / (y2 - y1) + x1;

        this.marginTop.next(`${y - y1}px`);
        this.marginLeft.next(`${x - x1}px`);

        this.isEyeProcessed = false;
      }
    };

    document.documentElement.addEventListener('mousemove', mousemove, false);

    this._sub.on.once(Subscriber.events.unsubscribe, () => {
      document.documentElement.removeEventListener(
        'mousemove',
        mousemove,
        false,
      );
    });
  }
}
