import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';
import type { IDiscountCampaign } from '@studiobuki/shared/dist/discount/interfaces';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { HeaderService } from '@studiobuki/web-core/lib/header';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('wrap') wrapRef!: ElementRef<HTMLDivElement>;

  // @Output() topbarHeightChange = new BehaviorSubject(0);

  public readonly message$: Observable<IDiscountCampaign['message']>;

  private readonly _resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(({ contentRect: { height } }) => {
      this._updateTopbarHeight(height);
    });
  });

  constructor(
    private _headerService: HeaderService,
    private _discountService: DiscountService,
  ) {
    this.message$ = this._discountService.activeDiscountCampaign$.pipe(
      map((discountCampaign) => discountCampaign?.message || ''),
    );
  }

  ngAfterViewInit() {
    this._resizeObserver.observe(this.wrapRef.nativeElement);
  }

  ngOnDestroy() {
    this._resizeObserver.disconnect();
    this._headerService.topbarHeight$.next(0);
  }

  private _updateTopbarHeight(height: number) {
    if (this._headerService.topbarHeight$.value !== height) {
      this._headerService.topbarHeight$.next(height);
    }
  }
}
