import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import {
  checkError,
  checkUserGift,
} from '@studiobuki/shared/dist/gift/functions/checks';
import type { IOrderData } from '@studiobuki/shared/dist/interfaces';
import { getError } from '@studiobuki/shared/dist/utils';
import { FirebaseService } from '@studiobuki/web-core/lib/firebase';
import { OrderService } from '@studiobuki/web-core/lib/order';
import {
  ROUTE_PATH_PARAM_ORDER_ID,
  ROUTE_QUERY_PARAM_BOOK,
  ROUTE_QUERY_PARAM_GIFT,
} from '@studiobuki/web-core/lib/routing';
import { createBookInOrderObject } from 'src/utils';

export interface IGiftOrderPageResolverData {
  order: IOrderData;
}

/** creates order if not exist and returns as resolved */
@Injectable({
  providedIn: 'root',
})
export class GiftOrderPageResolver {
  constructor(
    private _firebaseService: FirebaseService,
    private _orderService: OrderService,
  ) {}

  /** @returns orderId */
  private async _createOrder(giftId: string, bookId: string) {
    const userGift = await this._firebaseService.getUserGift(giftId);

    if (!userGift) {
      throw getError('user gift not found', { giftId });
    }

    if (checkError(userGift)) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw userGift.error;
    }

    if (!checkUserGift(userGift)) {
      throw getError('invalid user gift', { userGift });
    }

    const { alias, cover, wrapping } = userGift.book;

    return this._orderService.createOrder(
      [createBookInOrderObject(alias, bookId, wrapping, cover)],
      giftId,
    );
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot,
  ) {
    const { paramMap, queryParamMap } = route;

    const giftId = queryParamMap.get(ROUTE_QUERY_PARAM_GIFT);
    const bookId = queryParamMap.get(ROUTE_QUERY_PARAM_BOOK);
    let orderId = paramMap.get(ROUTE_PATH_PARAM_ORDER_ID);

    try {
      if (!orderId) {
        if (!giftId || !bookId) {
          throw getError('missing required query parameters', {
            [ROUTE_QUERY_PARAM_GIFT]: giftId,
            [ROUTE_QUERY_PARAM_BOOK]: bookId,
          });
        }

        orderId = await this._createOrder(giftId, bookId);
      }

      const order = await this._orderService.getOrder(orderId);

      return {
        order,
      };
    } catch (error: unknown) {
      alert((error instanceof Error && error.message) || error);

      return undefined;
    }
  }
}
