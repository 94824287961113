import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { emailValidatorList } from '@studiobuki/shared/dist/data/books/inputs/validators';
import { EMAIL_ERROR } from '@studiobuki/shared/dist/data/books/inputs/validators.constants';
import { Logger } from '@studiobuki/shared/dist/logger';
import { EButtonColor } from '@studiobuki/web-core/lib/button';
import { EInputTheme } from '@studiobuki/web-core/lib/input';
import type { IResetData } from '../../auth-page.interfaces';

const log = new Logger('FormResetComponent');

@Component({
  selector: 'app-form-reset',
  templateUrl: './form-reset.component.html',
  styleUrls: ['./form-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormResetComponent {
  @Output() resetButtonClick = new EventEmitter<IResetData>();

  @Output() signInButtonClick = new EventEmitter<void>();

  public readonly formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', emailValidatorList),
  });

  public readonly EButtonColor = EButtonColor;

  public readonly EInputTheme = EInputTheme;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  // constructor() {}

  public onResetButtonClick() {
    const data = this.formGroup.value as IResetData;

    log.info('onResetButtonClick', data);
    this.resetButtonClick.emit(data);
  }

  public onSignInButtonClick() {
    log.info('onSignInButtonClick');
    this.signInButtonClick.emit();
  }
}
