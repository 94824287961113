import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TBookCover,
  TBookWrapping,
} from '@studiobuki/shared/dist/book/interfaces';
import { CURRENCY } from '@studiobuki/shared/dist/constants';
import type { IBookPrice } from '@studiobuki/shared/dist/data/books/prices/types';
import { includeShippingToBookPrice } from '@studiobuki/shared/dist/data/books/prices/utils';
import type { IBook } from '@studiobuki/shared/dist/data/books/types';
import { IShipping } from '@studiobuki/shared/dist/data/shipping/types';

@Component({
  selector: 'app-section-gift-cart[cover][wrapping]',
  templateUrl: './section-gift-cart.component.html',
  styleUrls: ['./section-gift-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftCartComponent {
  @Input() book?: IBook | undefined;

  @Input() cover!: TBookCover;

  @Input() wrapping!: TBookWrapping;

  @Input() shipping!: IShipping;

  @Input() discount?: string | undefined | null;

  @Input() shippingDiscount?: string | undefined | null;

  get description(): string {
    const {
      book,
      cover,
      // wrapping,
    } = this;

    return book
      ? `『${book.caption}』ギフトコード<br>${book.covers[cover].name}・送料込み`
      : 'まだ商品が選ばれていません';
  }

  get price(): IBookPrice {
    const { book, cover, shipping } = this;

    const price = book?.prices.find(([_cover]) => _cover === cover)?.[1];

    return price
      ? includeShippingToBookPrice(price, shipping)
      : {
          value: 0,
          currency: CURRENCY,
        };
  }

  // constructor() {}
}
