import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookPrice } from '@studiobuki/shared/dist/data/books/prices/types';
import { ISpecs } from 'src/types';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileComponent {
  @Input() id!: string;

  @Input() title!: string;

  @Input() subtitle!: string;

  @Input() specs!: ISpecs;

  @Input() price!: IBookPrice;

  @Input() photo!: string;

  @Input() discount: string | undefined;

  @Output() close = new EventEmitter<void>();

  get specsKeys(): string[] {
    return Object.keys(this.specs);
  }

  // constructor() {}

  public onClose() {
    this.close.emit();
  }
}
