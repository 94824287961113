import type { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { Logger } from '@studiobuki/shared/dist/logger';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import { RoutingService } from 'src/app/services/routing.service';

const log = new Logger('NotFoundPageComponent');

@Component({
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements AfterViewInit {
  constructor(
    private _loader: LoaderService,
    private _routing: RoutingService,
  ) {}

  ngAfterViewInit(): void {
    this._loader.hide();
  }

  onHomeButtonClick() {
    log.info('onHomeButtonClick');
    return this._routing.goToIndex();
  }

  onBooksButtonClick() {
    log.info('onBooksButtonClick');
    // this._routing.goToAllBooks();
  }
}
