import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import { filter } from 'rxjs';
import { HeaderService } from '@studiobuki/web-core/lib/header';
import { UserService } from '@studiobuki/web-core/lib/user';
import { Logger } from '@studiobuki/shared/dist/logger';
import { ELayoutFooter, ELayoutHeader } from '@studiobuki/web-core/lib/routing';
import { gtagTrigger } from 'src/analytics';
import { updateMetaTokyoMetro } from '@studiobuki/shared/dist/meta';
import WelcomeToTheWorld from './data/books/WelcomeToTheWorld';
import { footerMainMenu, footerSideMenu } from './data/footer/data';

const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public LayoutHeader?: ELayoutHeader;

  public LayoutFooter?: ELayoutFooter;

  public overflow: 'hidden' | null = null;

  public readonly LAYOUT_HEADER = ELayoutHeader;

  public readonly LAYOUT_FOOTER = ELayoutFooter;

  public readonly WelcomeToTheWorld = WelcomeToTheWorld;

  public readonly footerMainMenu = footerMainMenu;

  public readonly footerSideMenu = footerSideMenu;

  constructor(
    public router: Router,
    public headerService: HeaderService,
    private _route: ActivatedRoute,
    private _loaderService: LoaderService,
    private _user: UserService,
    private _cdr: ChangeDetectorRef,
  ) {
    if (this.router.navigated) {
      this._setRouteData(this._getLastRouteState(this._route.snapshot));
    }

    this.router.events
      .pipe(
        filter(
          (event): event is ActivationEnd => event instanceof ActivationEnd,
        ),
      )
      .subscribe((event) => {
        this._setRouteData(this._getLastRouteState(event.snapshot));
        gtagTrigger('event', 'page_view');
      });

    this._user.init().catch((err) => log.error(err));
  }

  ngOnInit() {
    this._loaderService.shown$.subscribe((shown) => {
      this.overflow = shown ? 'hidden' : null;
      this._cdr.detectChanges();
    });

    // this.translateService.use(environment.defaultLocale);
  }

  private _setRouteData(snapshot: ActivatedRouteSnapshot) {
    const url = window.location.pathname.slice(1);

    updateMetaTokyoMetro(url, window.document);

    const { LayoutHeader, LayoutFooter } = snapshot.data;

    this.LayoutHeader =
      LayoutHeader !== undefined ? LayoutHeader : ELayoutHeader.shown;
    this.LayoutFooter =
      LayoutFooter !== undefined ? LayoutFooter : ELayoutFooter.shown;
  }

  private _getLastRouteState(
    snap: ActivatedRouteSnapshot,
  ): ActivatedRouteSnapshot {
    const firstChild = snap.children[0];

    if (firstChild) {
      return this._getLastRouteState(firstChild);
    }

    return snap;
  }
}
