import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import type {
  TRegion,
  TShippingMethod,
} from '@studiobuki/shared/dist/shipping/interfaces';
import { getRegionByNameEnglish } from '@studiobuki/shared/dist/shipping/utils';
import {
  REGION_NAME_ENGLISH_DEFAULT,
  SHIPPING_METHOD_REGULAR,
} from '@studiobuki/shared/dist/shipping/constants';
import type { IFormData } from '../../interfaces';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  @Input() touchEventEmitter!: EventEmitter<void>;

  @Output() dataChange = new EventEmitter<IFormData>();

  @Input() isBackDisabled = false;

  @Input() isNextDisabled = false;

  @Output() backClick = new EventEmitter<void>();

  @Output() nextClick = new EventEmitter<void>();

  public formEmail: IFormData['formEmail'];

  public formShippingAddress: IFormData['formShippingAddress'];

  public readonly environment = environment;

  get region(): TRegion | undefined {
    const form = this.formShippingAddress;

    if (form) {
      return getRegionByNameEnglish(form.addressLine1);
    }

    return getRegionByNameEnglish(REGION_NAME_ENGLISH_DEFAULT);
  }

  shippingMethod$ = new BehaviorSubject<TShippingMethod>(
    SHIPPING_METHOD_REGULAR,
  );

  // constructor() {}

  public onChange() {
    const data: IFormData = {
      formEmail: this.formEmail,
      formShippingAddress: this.formShippingAddress,
    };

    this.dataChange.emit(data);
  }
}
