import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBookPrice } from '@studiobuki/shared/dist/data/books/prices/types';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-toggle-cards-item[name][description][price][value]',
  templateUrl: './toggle-cards-item.component.html',
  styleUrls: ['./toggle-cards-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleCardsItemComponent {
  @Input() name!: string;

  @Input() description!: string;

  @Input() price!: IBookPrice;

  @Input() value!: any;

  @Input() disabled = false;

  @Input() discount: string | undefined;

  public active$ = new BehaviorSubject(false);

  // eslint-disable-next-line @angular-eslint/no-output-native
  click$ = new Subject<void>();

  // constructor() {}

  onClick() {
    this.click$.next();
  }
}
