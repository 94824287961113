import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { ROUTE_PATH_GIFT_USE } from '@studiobuki/web-core/lib/routing';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent {
  @Input() orderId?: string | undefined;

  @Input() giftId?: string | undefined;

  get giftUsePath(): string {
    return this.giftId ? `/${ROUTE_PATH_GIFT_USE}/${this.giftId}` : '';
  }

  get location() {
    return location;
  }

  constructor(public routing: RoutingService, public media: MediaService) {}
}
