import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { emailValidatorList } from '@studiobuki/shared/dist/data/books/inputs/validators';
import { EMAIL_ERROR } from '@studiobuki/shared/dist/data/books/inputs/validators.constants';
import { Logger } from '@studiobuki/shared/dist/logger';
import { EButtonColor } from '@studiobuki/web-core/lib/button';
import { EInputTheme } from '@studiobuki/web-core/lib/input';
import type { ISignInData } from '../../auth-page.interfaces';

const log = new Logger('FormSignInComponent');

@Component({
  selector: 'app-form-sign-in',
  templateUrl: './form-sign-in.component.html',
  styleUrls: ['./form-sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSignInComponent implements OnInit {
  @Output() signInButtonClick = new EventEmitter<ISignInData>();

  @Output() signUpButtonClick = new EventEmitter<void>();

  @Output() resetButtonClick = new EventEmitter<void>();

  public readonly formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', emailValidatorList),
    password: new UntypedFormControl('', [Validators.required]),
  });

  public readonly EButtonColor = EButtonColor;

  public readonly EInputTheme = EInputTheme;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  constructor() {}

  ngOnInit(): void {}

  public onSignInButtonClick() {
    const data = this.formGroup.value as ISignInData;

    log.info('onSignInButtonClick', data);
    this.signInButtonClick.emit(data);
  }

  public onSignUpButtonClick() {
    log.info('onSignUpButtonClick');
    this.signUpButtonClick.emit();
  }

  public onResetButtonClick() {
    log.info('onResetButtonClick');
    this.resetButtonClick.emit();
  }
}
