import {
  INPUT_VALUE_WRAPPING_GIFT,
  INPUT_VALUE_WRAPPING_STANDART,
} from '@studiobuki/shared/dist/book/constants';
import type { IWrapping } from '@studiobuki/shared/dist/data/books/types';

export const wrappingDefault: IWrapping = {
  id: INPUT_VALUE_WRAPPING_STANDART,
  name: 'No',
  price: {
    value: 0,
    currency: '¥',
  },
  label: 'Standart Wrapping',
};

export const wrappingFilled: IWrapping = {
  id: INPUT_VALUE_WRAPPING_GIFT,
  name: 'Gift Wrapping',
  price: {
    value: 300,
    currency: '¥',
  },
  image: 'assets/images/cart-select-option-photo.jpg',
  description:
    'We will wrap the picture book in the wrapping paper, and we will deliver it with a ribbon. To one important book of the important child.',
};

export const wrappings: IWrapping[] = [wrappingFilled, wrappingDefault];

/** default product object */
// export const product: Product = {
//   alias: BOOK_ALIAS_STARRY_DREAM,
//   bookId: '',
//   type: '絵本',
//   name: '”ほしのゆめ”',
//   cover: 'soft',
//   hero: 'Yuri',
//   price: 3900,
//   currency: '円',
//   image: 'assets/images/cart-product-photo.jpg',
//   wrappings,
//   wrapping: wrappingDefault
// }
