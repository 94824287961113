import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookPrice } from '@studiobuki/shared/dist/data/books/prices/types';
import { MediaService } from '@studiobuki/web-core/lib/media';
import SwiperCore, { Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-cover-card',
  templateUrl: './cover-card.component.html',
  styleUrls: ['./cover-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverCardComponent {
  @Input() active = false;

  @Input() recommended = false;

  @Input() carousel!: string[];

  @Input() title!: string;

  @Input() price!: IBookPrice;

  /** supports html tags */
  @Input() summary!: string;

  @Input() buttonText!: string;

  @Input() buttonTextActive!: string;

  @Output() buttonClick = new EventEmitter<void>();

  constructor(public media: MediaService) {}
}
