import {
  INPUT_VALUE_COVER_TYPE_SOFT,
  INPUT_VALUE_COVER_TYPE_HARD,
} from '@studiobuki/shared/dist/book/constants';
import type { ICover } from '@studiobuki/shared/dist/data/books/types';

interface IGetCoverSoftParams {
  price: ICover['price'];
  carousel: ICover['carousel'];
  // recommended?: ICover['recommended'];
}

export const getCoverSoft = (params: IGetCoverSoftParams): ICover => ({
  id: INPUT_VALUE_COVER_TYPE_SOFT,
  name: 'ソフトカバー',
  summary: /* html */ `小さな子供の手でも持ちやすくめくりやすい、文庫本のような柔らかい表紙です。光沢のある厚紙を表紙に使用しています。`,
  image: 'assets/images/soft.png',
  recommended: false,
  buttonText: 'ソフトカバーを選択する',
  buttonTextActive: 'ソフトカバーを選択中',
  ...params,
});

export const getCoverHard = (params: IGetCoverSoftParams): ICover => ({
  id: INPUT_VALUE_COVER_TYPE_HARD,
  name: 'ハードカバー',
  summary: /* html */ `丈夫で耐久性のある硬い表紙。ギフトや記念にとっておきたい時はこちらがおすすめ！<br>（書店に並ぶ絵本の多くはハードカバーです）`,
  image: 'assets/images/hard.png',
  recommended: true,
  buttonText: 'ハードカバーを選択する',
  buttonTextActive: 'ハードカバーを選択中',
  ...params,
});
