import type { OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { fade } from '@studiobuki/web-core';
import { TNavigationItemChildren } from '../../types';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  animations: [fade()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent implements OnDestroy {
  @Input() title: string | undefined;

  @Input() link: string | undefined;

  @Input() href: string | undefined;

  @Input() target: '_blank' | '_self' | '_parent' | '_top' = '_self';

  @Input() svgIconSrc: string | undefined;

  @Input() children: TNavigationItemChildren = [];

  @Input() label: string | number | undefined;

  private _isDropdownActive = new BehaviorSubject(false);

  public isDropdownActive = this._isDropdownActive.asObservable();

  private _dropdownTimer?: number;

  private _sub = new Subscriber();

  constructor(private router: Router) {
    this._sub.push(
      this.router.events.subscribe((/* event */) => {
        this._isDropdownActive.next(false);
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  @HostListener('mouseenter')
  private _onMouseenter(): void {
    window.clearTimeout(this._dropdownTimer);

    this._isDropdownActive.next(true);
  }

  @HostListener('mouseleave')
  private _onMouseleave(): void {
    this._dropdownTimer = window.setTimeout(() => {
      this._isDropdownActive.next(false);

      delete this._dropdownTimer;
    }, 200);
  }
}
