import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { IShipping } from '@studiobuki/shared/dist/data/shipping/types';
import type { IDiscountCampaign } from '@studiobuki/shared/dist/discount/interfaces';
import { getShippingDiscount } from '@studiobuki/shared/dist/discount/utils';
import { Logger } from '@studiobuki/shared/dist/logger';
import type { TShippingMethod } from '@studiobuki/shared/dist/shipping/interfaces';
import { TRegion } from '@studiobuki/shared/dist/shipping/interfaces';
import { checkDisabledShippingMethod } from '@studiobuki/shared/dist/shipping/utils';
import { DiscountService } from '@studiobuki/web-core/lib/discount';

const log = new Logger('FormShippingMethodComponent');

@Component({
  selector: 'app-form-shipping-method[region][shippings][disabledShippings]',
  templateUrl: './form-shipping-method.component.html',
  styleUrls: ['./form-shipping-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormShippingMethodComponent implements OnChanges {
  @Input() region!: TRegion;

  @Input() disabledShippings!: TShippingMethod[];

  @Input() shippings!: IShipping[];

  @Input() set shipping(shipping: IShipping | undefined) {
    if (shipping && this.shipping !== shipping) {
      this._shipping = shipping;
      // this.shippingChange.emit(shipping);
      setTimeout(() => this.shippingChange.emit(shipping));
    }
  }

  get shipping() {
    return this._shipping;
  }

  private _shipping: IShipping | undefined;

  @Output() shippingChange = new EventEmitter<IShipping>();

  public readonly getShippingDiscount = (
    discountCampaign: IDiscountCampaign | undefined | null,
    shippingMethod: TShippingMethod,
  ) =>
    discountCampaign
      ? getShippingDiscount(discountCampaign, shippingMethod)
      : undefined;

  constructor(public discountService: DiscountService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { disabledShippings, shipping, shippings } = changes;

    if (shipping) {
      const { currentValue } = shipping;

      // check if current shipping is disabled
      this._deselectDisabled(currentValue);
    }

    if (shippings) {
      const { currentValue } = shippings;

      // check if current shipping is disabled
      this._deselectDisabled(undefined, currentValue);
    }

    if (disabledShippings) {
      const { currentValue } = disabledShippings;

      // check if current shipping is disabled
      this._deselectDisabled(undefined, undefined, currentValue);
    }
  }

  private _deselectDisabled(
    shipping = this.shipping,
    shippings = this.shippings,
    disabledShippings = this.disabledShippings,
  ) {
    // check if current shipping is disabled
    if (shipping && this.checkDisabled(shipping, disabledShippings)) {
      const nonDisabledShipping = shippings.find(
        (_shipping) => !this.checkDisabled(_shipping, disabledShippings),
      );

      this.shipping = nonDisabledShipping;

      if (!nonDisabledShipping)
        log.error('all shippings are disabled', {
          shipping,
          shippings,
          disabledShippings,
        });
    }
  }

  checkDisabled(
    shipping: IShipping,
    disabledShippings = this.disabledShippings,
  ): boolean {
    return checkDisabledShippingMethod(shipping.id, disabledShippings);
  }
}
