import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Logger } from '@studiobuki/shared/dist/logger';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { fade } from '@studiobuki/web-core';
import { BehaviorSubject, debounceTime, fromEvent } from 'rxjs';
import { scrollToSelector } from 'src/utils';

const log = new Logger('ScrollTopComponent');

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade()],
})
export class ScrollTopComponent implements OnInit, OnDestroy {
  @Input() selector?: string;

  public visible$ = new BehaviorSubject(false);

  private _sub = new Subscriber();

  // constructor() {}

  ngOnInit() {
    this._sub.push(
      fromEvent(window, 'scroll')
        .pipe(debounceTime(300))
        .subscribe(() => {
          const { selector } = this;
          let top = window.innerHeight;

          if (selector) {
            const element = document.querySelector(selector);

            if (!element) {
              log.error('element not found by selector', selector);
              return;
            }

            const { y } = element.getBoundingClientRect();

            // when (selector + window height) is not in view
            top = y + window.innerHeight * 2;
          }

          this.visible$.next(window.scrollY > top);
        }),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  onClick() {
    const { selector } = this;

    if (selector) {
      scrollToSelector(selector);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
