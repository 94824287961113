import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from 'src/app/services/routing.service';
import { Logger } from '@studiobuki/shared/dist/logger';

const log = new Logger('SectionFooterBannerComponent');

@Component({
  selector: 'app-section-footer-banner',
  templateUrl: './section-footer-banner.component.html',
  styleUrls: ['./section-footer-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFooterBannerComponent {
  constructor(private _routing: RoutingService) {}

  public onButtonClick() {
    log.info('onButtonClick');
    return this._routing.goToGiftBuy();
  }
}
