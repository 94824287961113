import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Logger } from '@studiobuki/shared/dist/logger';
import { AppModule } from './app/app.module';
import { scrollToElement } from './utils';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

window.addEventListener('load', () => {
  const style = document.createElement('style');

  style.innerHTML = /* css */ `
      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Black.otf") format("opentype");
        font-weight: 800;
        font-display: swap;
      }

      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Bold.otf") format("opentype");
        font-weight: 700;
        font-display: swap;
      }

      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Medium.otf") format("opentype");
        font-weight: 500;
        font-display: swap;
      }

      @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Regular.ttf") format("opentype");
        font-weight: 400;
        font-display: swap;
      }

      /* @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Light.otf") format("opentype");
        font-weight: 300;
        font-display: swap;
      } */

      /* @font-face {
        font-family: NotoSansJP;
        src: url("assets/fonts/NotoSansJP/NotoSansJP-Thin.otf") format("opentype");
        font-weight: 200;
        font-display: swap;
      } */
    `;

  document.head.appendChild(style);
});

const clickHandlerLog = new Logger('CLICK HANDLER');
/**
 * as we have updated our base href we need to fix anchor's logic
 */
window.addEventListener('click', (event) => {
  const log = clickHandlerLog;
  const { target } = event;

  if (target instanceof HTMLAnchorElement) {
    const href = target.getAttribute('href');

    if (!href) {
      log.warn('skipped anchor element that missing href', target);
      return;
    }

    if (href.startsWith('#') && href.length > 1) {
      event.preventDefault();

      // log.info('ANCHOR');

      const id = href.slice(1);
      const element = document.getElementById(href.slice(1));

      if (!element) {
        log.error(`element not found by id "${id}" in anchor`, target);
        return;
      }

      scrollToElement(element);
    }
  }
});

// konva loading fix
const ImageSetter = Image.prototype.__lookupSetter__('src');

// eslint-disable-next-line no-restricted-properties
Image.prototype.__defineSetter__(
  'src',
  // eslint-disable-next-line func-names
  function (this: HTMLImageElement, src: string) {
    ImageSetter.bind(this)(src);

    if (src.startsWith('assets/')) {
      this.setAttribute('crossOrigin', 'anonymous');
    }
  },
);
