import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@studiobuki/shared/dist/logger';
import type { TBookData } from '@studiobuki/shared/dist/models';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { FirebaseService } from '@studiobuki/web-core/lib/firebase';
import { combineLatest } from 'rxjs';
import {
  ROUTE_PATH_QUERY_PARAM_MODE,
  ROUTE_PATH_QUERY_PARAM_MODE_PRINT,
} from '@studiobuki/web-core/lib/routing';

const log = new Logger('BookComponent');
const sub = new Subscriber();

/**
 * used to redirect to specific book component by `bookId` provided
 */
@Component({
  template: '',
})
export class BookPageComponent implements OnInit, OnDestroy {
  constructor(
    private _firebaseService: FirebaseService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.loadBookAlias();
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private loadBookAlias() {
    const { paramMap, queryParamMap } = this._route;

    sub.push(
      combineLatest([paramMap, queryParamMap]).subscribe(
        async ([_paramMap, _queryParamMap]) => {
          const bookId = _paramMap.get('id') as string;
          const mode = _queryParamMap.get(ROUTE_PATH_QUERY_PARAM_MODE);

          // * do not handle !bookId because component doesn't handle such a route
          // if (!bookId) {
          //   await this.router.navigate(['/404'], {replaceUrl: true});
          //   return;
          // }

          let bookData: TBookData | undefined;

          if (mode === ROUTE_PATH_QUERY_PARAM_MODE_PRINT) {
            bookData = (
              await this._firebaseService.getPrintBook<TBookData>(bookId)
            )?.bookData;
          } else {
            bookData = await this._firebaseService.getUserBook(bookId);
          }

          if (!bookData) {
            await this._router.navigate(['/404'], { replaceUrl: true });
            return;
          }

          const { alias } = bookData;

          log.info({ alias });

          await this._router.navigate(['book', alias, bookId], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
      ),
    );
  }
}
