import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Input, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@studiobuki/web-core/lib/user';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RoutingService } from 'src/app/services/routing.service';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { TRegionNameEnglish } from '@studiobuki/shared/dist/shipping/interfaces';
import { REGION_NAME_ENGLISH_DEFAULT } from '@studiobuki/shared/dist/shipping/constants';
import type {
  IOrderData,
  TCouponData,
} from '@studiobuki/shared/dist/interfaces';
import { getBooksDiscount } from '@studiobuki/shared/dist/discount/utils';
import { LIMIT_BOOKS_TO_ORDER } from '@studiobuki/shared/dist/constants';
import { ProductionService } from '@studiobuki/web-core/lib/production';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { FirebaseService } from '@studiobuki/web-core/lib/firebase';
import type {
  ICover,
  IWrapping,
} from '@studiobuki/shared/dist/data/books/types';
import { Logger } from '@studiobuki/shared/dist/logger';
import type { IShipping } from '@studiobuki/shared/dist/data/shipping/types';
import {
  FEToBEProduct,
  getCalculatorItems,
  getProductByBookData,
  getProductPrice,
} from 'src/utils';
import type { TBookCover } from '@studiobuki/shared/dist/book/interfaces';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import type { IProduct } from 'src/types';
import { OrderService } from '@studiobuki/web-core/lib/order';
import { DeleteProductDialogComponent } from '../delete-product-dialog/delete-product-dialog.component';

const log = new Logger('SectionCartComponent');

@Component({
  selector: 'app-section-cart',
  templateUrl: './section-cart.component.html',
  styleUrls: ['./section-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCartComponent implements OnInit, OnDestroy {
  @Input() set region(value: TRegionNameEnglish) {
    this.region$.next(value);
  }

  public readonly region$ = new BehaviorSubject<TRegionNameEnglish>(
    REGION_NAME_ENGLISH_DEFAULT,
  );

  // public orderId$ = new ReplaySubject<IOrderData['orderId']>(1);
  public readonly order$ = new BehaviorSubject<IOrderData | undefined>(
    undefined,
  );

  public readonly products$ = this.userService.booksInCartList$.pipe(
    map((books) => {
      log.info('load: _userServiceBooksList map');

      return books.map((book) => getProductByBookData(book));
    }),
  );

  public readonly getBooksDiscount = getBooksDiscount;

  public readonly isLimitExceeded$ = this.products$.pipe(
    map((products) => products.length > LIMIT_BOOKS_TO_ORDER),
  );

  public readonly isLoading$ = new BehaviorSubject(false);

  public readonly isProceed$ = combineLatest([
    this.products$,
    this.isLimitExceeded$,
    this.isLoading$,
  ]).pipe(
    map(
      ([products, isLimitExceeded, isLoading]) =>
        !isLoading && products.length && !isLimitExceeded,
    ),
  );

  public readonly shipping$ = this._productionService
    .getShippings$(
      combineLatest([this.products$, this.region$]).pipe(
        map(([products, region]) => ({
          booksCount: products.length || 1,
          regionName: region,
        })),
      ),
    )
    .pipe(
      map((shippings) => shippings[0]),
      filter((method): method is IShipping => !!method),
    );

  public readonly booksInOrder$ = this.products$.pipe(
    map((products) => products.map(FEToBEProduct)),
  );

  /** updated by `<app-coupon>` */
  public readonly coupon$ = new BehaviorSubject<TCouponData | undefined>(
    undefined,
  );

  public readonly calculatorItems$ = combineLatest([
    this.products$,
    this.discountService.activeDiscountCampaign$,
    this.coupon$,
    this.shipping$,
  ]).pipe(
    map(([products, discountCampaign, coupon, shipping]) =>
      getCalculatorItems(products, discountCampaign, coupon, shipping),
    ),
  );

  public coverNameMap: { [key in TBookCover]: string } = {
    hard: 'Hard Cover',
    soft: 'Soft Cover',
  };

  public readonly LIMIT_BOOKS_TO_ORDER = LIMIT_BOOKS_TO_ORDER;

  private _sub = new Subscriber();

  constructor(
    public router: Router,
    public userService: UserService,
    public discountService: DiscountService,
    private _orderService: OrderService,
    private _firebaseService: FirebaseService,
    private _dialog: MatDialog,
    private _routing: RoutingService,
    private _loaderService: LoaderService,
    private _productionService: ProductionService,
  ) {}

  public readonly getProductPrice = getProductPrice;

  async ngOnInit() {
    const orderId = await this.createOrder();

    this._sub.push(
      this._firebaseService.getUserOrder$(orderId).subscribe((order) => {
        this.order$.next(order);
      }),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  public removeProduct(product: IProduct) {
    this._dialog
      .open(DeleteProductDialogComponent, {
        data: {
          product,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        log.info(`Dialog result: ${result}`);

        if (result) {
          await this._firebaseService.deleteUserBook(product.bookId);
        }
      });
  }

  public async selectWrapping(p: IProduct, name: IWrapping['name']) {
    const wrapping = p.wrappings.find((w) => w.name === name);

    if (!wrapping) {
      log.error(
        'selectWrapping - wrapping not found by name',
        name,
        'in product',
        p,
      );
      return;
    }

    await this._firebaseService.setUserBook(
      p.bookId,
      { wrapping: wrapping.id },
      { merge: true },
    );
  }

  public async selectCover(p: IProduct, id: ICover['id']) {
    const cover = p.covers.find((c) => c.id === id);

    if (!cover) {
      log.error('selectCover - cover not found by id', id, 'in product', p);
      return;
    }

    await this._firebaseService.setUserBook(
      p.bookId,
      { cover: id },
      { merge: true },
    );
  }

  public addAnotherBookButtonClick() {
    log.info('addAnotherBookButtonClick');
    return this._routing.goToIndex({ fragment: 'preform' });
  }

  /**
   * Navigates to order page. Make sure you do check by isProceed
   * before triggering this funciton.
   */
  async proceed() {
    this.isLoading$.next(true);

    const orderData = await firstValueFrom(this.order$);
    let orderId = orderData?.orderId;

    this._loaderService.show();

    if (orderId) {
      await this.updateOrder(orderId);
    } else {
      orderId = await this.createOrder();
    }

    this.isLoading$.next(false);

    return this.router.navigate(['order', orderId]);
  }

  /**
   * Returns orderId and creates new order if we didn`t
   * have it before
   */
  private async createOrder() {
    this.isLoading$.next(true);

    const booksInOrder = await firstValueFrom(this.booksInOrder$);
    const orderId = await this._orderService.createOrder(booksInOrder);

    this.isLoading$.next(false);

    return orderId;
  }

  private async updateOrder(orderId: IOrderData['orderId']) {
    const booksInOrder = await firstValueFrom(this.booksInOrder$);

    await this._orderService.updateOrder(orderId, { books: booksInOrder });
  }
}
